<template>
    <section>
      <!-- consumo -->
      <b-row>
        <b-col md="3" lg="3" v-for="(stat, idx) in stats1" :key="idx">
          <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <bruna-element-loader :dataUrl="prediccionPesoPromedioVaraFriaChart" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <bruna-element-loader :dataUrl="prediccionDistribucionLomosVaraFriaChart" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
      </b-row>   
      
      <b-row>
      <b-col>
        <ag-grid-table
          ref="optimization-comments-table-3"
          :debug="this.$route.query.debug == '1'"
          :configUrl="prediccionesVaraFriaTabla"
          rowModelType="clientSide"
        ></ag-grid-table>
      </b-col>
    </b-row>      

    <!--

      <b-row>
        <b-col sm="12">
          <ag-grid-table ref="grid-historico-consumo" :debug="true" :configUrl="tabla01Config" :dataUrl="tabla01Data">
          </ag-grid-table>
        </b-col>
      </b-row>
      -->
    </section>
  </template>
            
  <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  import Stat from '@/views/clients/base/components/Stat.vue'
  import useApiServices from '@/services/useApiServices.js';
  import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  import axios from "@axios";
  var qs = require('qs');
  
  import {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  
    BOverlay,
    BSpinner
  
  } from "bootstrap-vue";
  
  export default {
    components: {
      AgGridTable,
      BRow,
      BCol,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BInputGroup,
      BFormInput,
      BFormGroup,
      BFormTextarea,
  
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
  
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
  
      Stat,
  
      BOverlay,
      BSpinner,
  
      BrunaForm,
      BrunaElementLoader,
    },
  
    methods: {
  
      loadStats() {
        axios.request({
          method: "get",
          url: this.stats01Url,
          headers: {
            Accept: "application/json",
          },
        })
          .then((response) => {
            this.stats1 = response.data
          })
      },
    },
  
    mounted() {
      this.loadStats();
    },
  
    created() {
  
    },
  
    watch: {
      planta_selected: {
  
        handler(newValue, oldValue) {
  
          let query = qs.stringify({ planta: newValue })
  
          // this.stats00Url = useApiServices.modeloPrediccion.stats00 + "?" + query
          this.stats01Url = useApiServices.modeloPrediccion.stats01 + "?" + query
  
          // this.tabla00Config = useApiServices.modeloPrediccion.table00Config + "?" + query
          // this.tabla00Data = useApiServices.modeloPrediccion.table00Data + "?" + query
  
          this.tabla01Config = useApiServices.modeloPrediccion.table01Config + "?" + query
          this.tabla01Data = useApiServices.modeloPrediccion.table01Data + "?" + query
  
          // this.prediccionAceiteChart00 = useApiServices.modeloPrediccion.prediccionAceiteChart00 + "?" + query
          this.prediccionPesoPromedioVaraFriaChart = useApiServices.modeloPrediccion.prediccionPesoPromedioVaraFriaChart + "?" + query
          this.prediccionDistribucionLomosVaraFriaChart = useApiServices.modeloPrediccion.prediccionDistribucionLomosVaraFriaChart + "?" + query

          
          this.loadStats();
        },
      },
    },
  
    props: {
      planta_selected: {
        type: String,
        required: false,
        default: () => null
      },
    },
  
    data() {
  
      let debug = (this.$route.query.debug == '1')
      return {
        stats: [],
        stats1: [],
  
        // stats00Url: useApiServices.modeloPrediccion.stats00,
        stats01Url: useApiServices.modeloPrediccion.stats01,
  
        // prediccionAceiteChart00: useApiServices.modeloPrediccion.prediccionAceiteChart00,
        prediccionPesoPromedioVaraFriaChart: useApiServices.modeloPrediccion.prediccionPesoPromedioVaraFriaChart,
        prediccionDistribucionLomosVaraFriaChart: useApiServices.modeloPrediccion.prediccionDistribucionLomosVaraFriaChart,
        prediccionesVaraFriaTabla: useApiServices.modeloPrediccion.prediccionesVaraFriaTabla,

        debug: debug,
        // tabla00Config: useApiServices.modeloPrediccion.table00Config,
        // tabla00Data: useApiServices.modeloPrediccion.table00Data,
  
        tabla01Config: useApiServices.modeloPrediccion.table01Config,
        tabla01Data: useApiServices.modeloPrediccion.table01Data,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  span {
    font-size: 14px;
  }
  </style>
          
          
  <style lang="scss" >
  @import "@core/scss/vue/libs/vue-slider.scss";
  
  .escenario-base {
    background-color: #00bcd4 !important;
  }
  </style>
          
  <style lang="scss" scoped>
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
            